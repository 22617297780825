/********** P-DROPDOWN | P-MULTISELECT **********/
p-dropdown,
p-multiselect {
    width: 100%;
}

div.p-dropdown,
div.p-multiselect {
    @apply border border-cb-divider;
    @apply rounded-md;
    @apply py-1;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 100%;
}

span.p-dropdown-label,
div.p-multiselect-label {
    padding: 0.3rem 0rem !important;
}

div.p-dropdown.p-dropdown-open,
div.p-dropdown:focus-within,
div.p-multiselect.p-multiselect-open,
div.p-multiselect:focus-within {
    @apply border-cb-primary-basic;
    @apply outline outline-2 outline-cb-primary-transparent;
}

p-dropdown.error div.p-dropdown,
p-multiselect.error div.p-multiselect {
    @apply border-cb-danger-basic;
}

p-dropdown.error div.p-dropdown.p-dropdown-open,
p-dropdown.error div.p-dropdown:focus-within,
p-multiselect.error div.p-multiselect.p-multiselect-open,
p-multiselect.error div.p-multiselect:focus-within {
    @apply outline outline-2 outline-cb-danger-transparent;
}

div.p-dropdown-trigger {
    margin-left: 0.5rem;
}

div.p-dropdown-panel,
div.p-multiselect-panel {
    @apply border border-cb-divider;
    @apply mt-2;
    @apply rounded-md;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

div.p-multiselect-header {
    display: none;
}

li.p-multiselect-empty-message {
    @apply flex items-center justify-center;
    @apply text-cb-hint;
    @apply p-3 rounded-md;
    @apply bg-cb-background-1;
}

.p-dropdown-label,
.p-multiselect-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.p-placeholder {
    @apply text-cb-hint;
}

p-dropdownitem:first-child>li.p-dropdown-item,
p-multiselectitem:first-child>li.p-multiselect-item {
    @apply rounded-tl-md rounded-tr-md;
}

p-dropdownitem:last-child>li.p-dropdown-item,
p-multiselectitem:last-child>li.p-multiselect-item {
    @apply rounded-bl-md rounded-br-md;
}

li.p-dropdown-empty-message {
    @apply bg-cb-background-1;
    @apply py-3 px-4;
    @apply rounded-md;
    text-align: center;
}

li.p-dropdown-item,
li.p-multiselect-item {
    @apply px-2 py-1;
    @apply bg-cb-background-1;
    @apply hover:bg-cb-background-2 active:bg-cb-background-3;
}

p-dropdown[ng-reflect-disabled="true"] {
    opacity: 0.5;
    pointer-events: none;
}

input.p-inputtext {
    width: 100% !important;
    outline: none !important;
}

/********** P-CHECKBOX **********/
div.p-checkbox {
    @apply mr-2;
}

div.p-checkbox-box {
    transition: 0.05s;
    @apply flex items-center justify-center;
    @apply border border-cb-divider hover:border-cb-primary-hover;
    @apply bg-transparent hover:bg-cb-primary-light;
    @apply rounded-md;
    width: 1.4rem;
    height: 1.4rem;
}

div.p-checkbox-box.p-highlight {
    @apply bg-cb-primary-basic;
}

span.p-checkbox-icon {
    @apply text-cb-primary-on;
    font-size: 0.75rem;
}

/********** P-SLIDER **********/
div.p-slider-horizontal {
    @apply bg-cb-background-2;
    @apply rounded-md;
    height: 3px;
    position: relative;
}

span.p-slider-range {
    @apply bg-cb-primary-basic;
    position: absolute;
    display: block;
}

span.p-slider-handle {
    @apply border-2 border-cb-primary-basic;
    @apply bg-cb-background-1;
    @apply outline-0 active:outline active:outline-2 active:outline-cb-primary-transparent;
    transition: 0.05s;
    width: 1rem;
    height: 1rem;
    position: absolute;
    display: block;
    border-radius: 1rem;
    top: -0.475rem !important;
}

/********** P-CALENDAR **********/
p-calendar input {
    padding: 0.3rem 0.5rem !important;
}

p-calendar,
p-calendar span.p-calendar {
    width: 100%;
}

div.p-datepicker {
    @apply rounded-md;
    @apply p-2 mt-4;
    @apply bg-cb-background-1;
    z-index: 3000;
    position: absolute;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

div.p-datepicker-header {
    @apply mb-2;
    @apply px-2 py-1;
}

div.p-datepicker-header div.p-datepicker-title button {
    @apply mx-1;
    @apply py-1 px-2;
    @apply rounded-md;
    @apply bg-cb-background-1 hover:bg-cb-background-2 active:bg-cb-background-3;
}

div.p-yearpicker span,
div.p-monthpicker span {
    @apply py-1 px-2;
    @apply rounded-md;
    @apply bg-cb-background-1 hover:bg-cb-background-2 active:bg-cb-background-3;
}

table.p-datepicker-calendar th,
table.p-datepicker-calendar td {
    @apply p-2;
}

table.p-datepicker-calendar td span {
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
}

table.p-datepicker-calendar td.p-datepicker-other-month span {
    @apply text-cb-hint;
}

table.p-datepicker-calendar td:hover span {
    @apply bg-cb-background-2;
}

table.p-datepicker-calendar td:active span {
    @apply bg-cb-background-3;
}

table.p-datepicker-calendar td span.p-highlight {
    @apply bg-cb-primary-basic;
    @apply text-cb-primary-on;
}

/********** NGX-FILE-PICKER **********/
div.cb-drop-zone {
    @apply flex items-center justify-center;
    @apply border-2 border-dashed border-cb-hint;
    @apply rounded-lg;
    @apply p-4;

}

div.cb-drop-zone.ngx-file-drop__drop-zone--over {
    @apply bg-cb-background-2;
    @apply border-cb-primary-basic;
}

/********** P-TOOLTIP **********/
div.p-tooltip {
    @apply absolute;
    @apply rounded-md;
    background: rgba(0, 0, 0, 0.8);
    text-align: center;
    z-index:6000 !important;
}

div.p-tooltip.p-tooltip-top {
    @apply mb-1;
}

div.p-tooltip.p-tooltip-right {
    @apply ml-1;
}

div.p-tooltip.p-tooltip-left {
    @apply mr-1;
}

div.p-tooltip.p-tooltip-bottom {
    @apply mt-1;
}

div.p-tooltip div.p-tooltip-text {
    @apply mx-3 my-1;
    color: #fff;
    font-size: 0.8rem;
}

/********** CDK-DRAG **********/
div.cdk-drop-list-dragging>div {
    transition: ease-out 0.1s;
}

div.cdk-drag-preview {
    @apply bg-cb-background-1;
    @apply rounded-md;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
}

div.cdk-drag-placeholder {
    opacity: 0 !important;
}

/********** PDF-VIEWER **********/
pdf-viewer {
    width: 100%;
    height: 100%;
}

/********** P-OVERLAY **********/
div.p-overlaypanel {
    @apply rounded-md;
    @apply border border-cb-divider;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
    margin-top: 0.4rem;
}

div.p-overlaypanel div.p-overlaypanel-content {
    padding: 0;
}

div.p-overlaypanel-content {
    @apply bg-cb-background-1;
    @apply rounded-md;
    overflow: hidden;
    position: relative;
}

div.p-overlaypanel::before {
    display:none;
    /* @apply border-cb-background-1;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    content: "";
    transform: rotate(45deg);
    top: -0.4rem; */
}

div.p-overlaypanel::after {
    display: none;
}

/********** CDK-DIALOG **********/
div.cdk-overlay-backdrop {
    background: rgba(0, 0, 0, 0.6);
}

div.cdk-overlay-pane {
    max-width: 98vw !important;
}

.mat-dialog-container {
    overflow: visible !important;
}

/********** P-CHIPS **********/
p-chips, .p-chips{
    width:100%;
}

p-chips ul{
    @apply flex flex-wrap gap-1;
    padding:0.3rem 0.5rem !important;
    width:100%;
}

li.p-chips-input-token input{
    @apply placeholder:text-cb-text-light;
}

li.p-chips-token{
    @apply bg-cb-primary-basic;
    @apply rounded-full;
    @apply text-sm;
    @apply px-2;
}

span.p-chips-token-label{
    @apply text-cb-primary-on;
    @apply mr-1;
}

span.p-chips-token-icon{
    @apply text-cb-primary-on;
}

/********** CB-BUTTON **********/
/* cb-button {
    @apply mx-1;
}

cb-button:first-child {
    @apply ml-0;
}

cb-button:last-child {
    @apply mr-0;
} */

/********** P-TOOLTIP **********/
/* .p-tooltip{
    z-index:5000;
    background:red;
} */

/********** P-INPUTMASK **********/
p-inputmask input {
    padding: 0.3rem 0.5rem !important;
}


/********** OTHERS **********/
.cb-error-message{
    @apply text-cb-danger-basic;
}

.p-hidden-accessible{
    display:none !important;
}