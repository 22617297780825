@use '@angular/material' as mat;
@include mat.core();

@import "./theme.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
    @apply text-cb-text;
    font-family: 'Montserrat', sans-serif;
}

html {
    font-size: 16px;
}

body {
    @apply bg-cb-background-2;
    margin: 0;
    padding: 0;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizelegibility;
}

input, textarea{
    @apply placeholder:text-cb-text-light;

    &.bg-title-input{
        font-family: 'DM Serif Display', serif;
    }
}



h1,h2,h3,h4,h5{
    font-family: 'DM Serif Display', serif;

    b, span, mark {
        font-family: 'DM Serif Display', serif;
    }

    b{
        @apply text-cb-primary-basic;
    }

}

h1 {
    @apply text-4xl;
}

h2 {
    @apply text-3xl;
}

h3 {
    @apply text-2xl;
}

h4{
    @apply text-xl;
}

h5{
    @apply text-lg;
}

p {
    font-size: 1rem;
    font-weight: 400;
}

.cdk-overlay-container{
    z-index: 2000;

    .cdk-overlay-backdrop{
        background-color: rgba(0, 0, 0, 0.85);
        backdrop-filter: blur(10px);
    }
}

div.bg-page{
    @apply w-full;
    @apply flex flex-col items-center;
    min-height:100vh;
    min-width:100vw;

    div.bg-page-header{
        @apply w-full;
        @apply px-6 py-4;
        @apply border-b border-b-cb-divider;
    }
    
    div.bg-page-footer {
        @apply p-4;
        @apply absolute bottom-0;
        @apply bg-cb-background-2;
        @apply w-full;
        
        div.bg-center-form-buttons{
            @apply mt-10;
            @apply flex justify-end gap-2;
        }

        div.bg-center-form-buttons-group{
            @apply mt-10;
            @apply flex justify-between gap-2;
            
            div.bg-center-form-buttons-left{
                @apply flex items-center gap-2;
            }

            div.bg-center-form-buttons-right{
                @apply flex items-center gap-2;
            }
        }
 
    }
}

div.bg-center-form-container{
    @apply p-4 mt-12 mb-20;
    width:800px;
    max-width:100%;

    div.bg-center-form{
        div.bg-center-form-header {
            @apply mb-4;
            @apply flex justify-between gap-2;

            cb-icon-button{
                @apply text-cb-primary-basic;
            }
 

            h1{
                @apply flex-1;
                @apply text-3xl;
            }

        }

        div.bg-center-form-question{
            @apply flex flex-col gap-2 pb-4;
            @apply bg-cb-background-2;
            
            div.bg-article-structure-headings-list {
                @apply flex-1 overflow-y-auto px-6 pb-24;
                @apply flex flex-col gap-2;
                @apply w-full;
                @apply bg-cb-background-2;
            }

            div.bg-center-form-question-header{
                @apply flex justify-between items-start gap-2;

                cb-icon-button{
                    @apply text-cb-primary-basic;
                }
                
                cb-button {
                    @apply p-0 m-0;
                }
            }

            p{
                @apply text-sm;
            }

            textarea{
                @apply bg-transparent mb-4 mt-2;
                @apply resize-none text-xl focus:outline-none;
            }

            span{
                @apply text-sm font-medium;
            }

            ul.bg-center-form-options{
                @apply flex flex-wrap gap-2;
                @apply mt-3 mb-6;

                li{
                    @apply select-none cursor-pointer;
                    @apply px-6 py-2 rounded-full;
                    @apply border-2 border-cb-dark-basic;
                    @apply hover:border-cb-primary-basic;
                    @apply font-medium;
                    @apply flex;

                    &.selected{
                        @apply bg-cb-primary-basic border-cb-primary-basic;
                        @apply text-cb-primary-on;
                    }
                    
                    cb-icon {
                        @apply ml-2;
                    }
                }

                &.bg-vertical-options{
                    @apply flex-col;

                    li{
                        @apply w-full;
                        @apply rounded-md;
                    }
                }
            }
        }

        div.bg-center-form-buttons{
            @apply mt-10;
            @apply flex justify-end gap-2;
        }
   }

    div.bg-center-form-loader{
        @apply flex flex-col items-center;
        @apply py-10 bg-cb-background-3 rounded-xl;

        cb-loader{
            @apply text-cb-primary-basic;
        }
    }
}

div.bg-article-heading {
    @apply flex items-center gap-3;
    
    cb-icon {
        @apply cursor-grab active:cursor-grabbing;
    }
    
    bg-header-article {
        @apply flex-1;
    }
}

div.bg-tokens-count {
    @apply flex items-center gap-2;
    @apply bg-cb-background-3 rounded-full p-2 pr-6 mb-4 mt-4;

    div.bg-tokens-count-value{
        @apply bg-cb-primary-basic rounded-full px-4;
        @apply font-semibold text-lg text-cb-primary-on;
    }

    div.bg-tokens-count-label{
        @apply font-medium;
    }
}

div.p-overlay{
    z-index:99999 !important;
}


/********** CDK-DRAG **********/
.cdk-drag	{
    box-shadow: none !important;
    background-color: transparent !important;
}
.cdk-drag-preview	{
    box-shadow: none !important;
    @apply bg-cb-background-2;
}
.cdk-drag-placeholder	{
    box-shadow: none !important;
    @apply bg-cb-background-2;
}